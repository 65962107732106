var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            "before-close": _vm.close,
            "close-on-click-modal": false,
            width: "50%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            open: _vm.openDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
            [
              _c("i"),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.dialogTitle) },
              }),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "班级名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入班级名称", clearable: "" },
                    on: {
                      input: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm.classInfo === null
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授课课程：", prop: "course_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择收费课程",
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.formData.course_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "course_id", $$v)
                            },
                            expression: "formData.course_id",
                          },
                        },
                        _vm._l(_vm.ProductList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "开班限制：", prop: "openClassNum" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入开班限制",
                      precision: 0,
                      min: -1,
                      controls: false,
                    },
                    model: {
                      value: _vm.formData.openClassNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "openClassNum", $$v)
                      },
                      expression: "formData.openClassNum",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        color: "#cca628",
                        "font-size": "12px",
                      },
                    },
                    [_vm._v("-1 为不限制开班")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开班日期：", prop: "open_date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "date",
                      placeholder: "选择开班日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formData.open_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "open_date", $$v)
                      },
                      expression: "formData.open_date",
                    },
                  }),
                ],
                1
              ),
              _vm.classInfo === null
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上课老师：", prop: "teacherIds" } },
                    [
                      _vm.classTeacherList.length === 0
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-s-operation",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.seleContainer("teacher")
                                  },
                                },
                              },
                              [_vm._v("选择老师")]
                            ),
                          ]
                        : _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { multiple: "", clearable: "" },
                              on: {
                                clear: function ($event) {
                                  return _vm.removeTag("teacher", true)
                                },
                                "remove-tag": function ($event) {
                                  return _vm.removeTag("teacher")
                                },
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.seleContainer("teacher")
                                },
                              },
                              model: {
                                value: _vm.formData.teacherIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "teacherIds", $$v)
                                },
                                expression: "formData.teacherIds",
                              },
                            },
                            _vm._l(_vm.classTeacherList, function (item) {
                              return _c("el-option", {
                                key: item.userId,
                                attrs: { label: item.name, value: item.userId },
                              })
                            }),
                            1
                          ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "dialog-btn",
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "dialog-btn",
                  attrs: { size: "small", type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
          _c("sele-container", {
            attrs: {
              show: _vm.containerDialog,
              type: _vm.containerType,
              checkData: _vm.checkData,
              courseId: _vm.courseId,
            },
            on: {
              close: function ($event) {
                _vm.containerDialog = false
              },
              seleContainerData: _vm.seleContainerData,
            },
          }),
        ],
        1
      ),
      _c("DoubleDetail", { ref: "doubleFlag" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }