import $axios from '@/utils/request'
/**
 * 查询商品包列表
 * @param {*} data
 * @returns
 */
export function getCourseGoodsNameList(data) {
    const url = '/course_goods/list'
    return $axios.fGet(url, data)
}
/**
 * 查询商户购买的收费课程列表
 * @param {*} data
 * @returns
 */
export function getCourseGoodsList(data) {
    const url = '/course/user/log/list'
    return $axios.fGet(url, data)
}
/**
 * /cms/merchant/course/user/list
 * @param {*} data
 * @returns
 */
export function getCourseMemberList(data) {
    const url = '/course/user/list'
    return $axios.fGet(url, data)
}
/**
 * 查询商户购买的收费课程列表
 * @param {*} data
 * @returns
 */
export function getCourseList(data) {
    const url = '/course/getCourseListByMerchant'
    return $axios.fPost(url, data)
}
/**
 * /cms/merchant/course/enterCourse
 * /cms/merchant/course/user/enterCourse
 * @param {*} data
 * @returns
 */
export function setCourseMember(data) {
    const url = '/course/user/enterCourse'
    return $axios.fPut(url, data)
}
/**
 * 查询课程列表
 * @param {*} data
 * @returns
 */
export function getCourseLists(data) {
    const url = '/course/list'
    return $axios.fPost(url, data)
}
/**
 * /cms/merchant/course/user/leaveCourse
 * @param {*} data
 * @returns
 */
export function leaveCourse(data) {
    const url = '/course/user/leaveCourse'
    return $axios.fPut(url, data)
}
/**
 *
 * @param {*} data
 * @returns
 * /cms/merchant/course/user/log/list
 */
export function getStudentCourseLogList(data) {
    const url = '/course/user/log/list'
    return $axios.fGet(url, data)
}
